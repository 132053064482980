<!--start header -->
<header>
	<div class="topbar d-flex align-items-center">
		<nav class="navbar navbar-expand">
			<div class="mobile-toggle-menu"><i (click)="toggleSidebar()" class='bx bx-menu'></i>
			</div>
			<div class="top-menu ms-auto">
			</div>
			<div class="user-box dropdown">
				<a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button"
					data-bs-toggle="dropdown" aria-expanded="false">
					<img src="../../../assets/images/admin1.jpg" class="user-img" alt="user avatar">
					<div class="user-info ps-3">
						<p class="user-name mb-0">Administrator</p>
						<p class="designattion mb-0">Profile</p>
					</div>
				</a>
				<ul class="dropdown-menu dropdown-menu-end">
					<li><a class="dropdown-item"  routerLink="/admin-profile" ><i class="bx bx-user"></i><span>Profile</span></a>
					</li>
					<li><a class="dropdown-item" (click)="userLogout()"><i
								class='bx bx-log-out-circle'></i><span>Logout</span></a>
					</li>
				</ul>
			</div>
		</nav>
	</div>
</header>
<!--end header -->
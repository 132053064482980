export const environment = {
  production: false,

  // Live Keys
  apiKeywebsite: 'https://sbbackend.cliffitsolutions.com/',
  apiKeygenToken: 'https://sbbackend.cliffitsolutions.com/auth/gentoken',
  apiKeyAdmin: 'https://sbbackend.cliffitsolutions.com/admin',

  // Local Host Live Keys
  // apiKeywebsite: "http://localhost:3000/",
  // apiKeygenToken: "http://localhost:3000/auth/gentoken",
  // apiKeyAdmin: 'http://localhost:3000/admin',

  cloudFrontURL: 'https://d4ukq5l7talw0.cloudfront.net/',

  bucket_name: 'isl-projects-cliffit',
  bucket_access_type: 'public-read',
  bucket_location: 'ap-south-1',
  // secrets_accessKey: 'AKIAYUJBWLFJC6R6PKFV',
  // secrets_secretKey: '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',
  // secrets_accessKey: 'AKIAYUJBWLFJJWCWDUU2',
  // secrets_secretKey: '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'
  secrets_accessKey: 'AKIAYUJBWLFJOQU5HC7U',
  secrets_secretKey: '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',
};

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { error } from 'console';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  apiKeywebsite: any;
  isLoggedin: boolean = false;
  userInfo: BehaviorSubject<any> = new BehaviorSubject(null);
  jwtHelper = new JwtHelperService();
  genTokenUrl: any;

  access_token = localStorage.getItem('access_token');

  constructor(private http: HttpClient) {
    this.loadUserInfo();
    this.apiKeywebsite = environment.apiKeywebsite;
    this.genTokenUrl = environment.apiKeygenToken;
  }

  loadUserInfo() {
    let userdata = this.userInfo.getValue();
    // console.log("userdata",userdata);
    if (!userdata) {
      const accesstoken = localStorage.getItem('access_token');
      if (accesstoken) {
        userdata = this.jwtHelper.decodeToken(accesstoken);
        this.userInfo.next(userdata);
        this.isLoggedin = true;
      }
    }
  }

  userLogin(logindata: any): Observable<boolean> {
    return this.http.post(this.apiKeywebsite + 'auth/login', logindata).pipe(
      map((value: any) => {
        if (value) {
          let date = Date.now() / 1000;
          var dateTime = date.toFixed();
          localStorage.setItem('access_token', value.token);
          localStorage.setItem('refresh_token', value.refresh_token);
          const decrypteduser = this.jwtHelper.decodeToken(value.token);
          localStorage.setItem('expiration', decrypteduser.exp);
          this.userInfo.next(decrypteduser);
          this.isLoggedin = true;
          return true;
        }
        return false;
      })
    );
  }

  public logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.clear();
    this.isLoggedin = false;
  }

  isLoggedIn() {
    // let data = localStorage.getItem('access_token');
    // let refreshToken = localStorage.getItem('refresh_token');
    // console.log(refreshToken);
    // console.log("Local Storage", data);
    if (localStorage.getItem('access_token') == null) {
      this.isLoggedin = false;
      return this.isLoggedin;
    } else {
      return true;
    }
  }

  callrefreshToken(): Observable<any> {
    // console.log("refresh token called");
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.access_token,
      },
    };
    const body = { refresh_token: this.getrefreshToken() }
    return this.http.post(this.apiKeywebsite + 'auth/gentoken', body , options );
  }

  forgotPassword(admin_email: any) {
    const body = admin_email;
    return this.http.post(this.apiKeywebsite + 'auth/forgot_password', body);
  }

  resetPassword(admin_data: any) {
    const body = admin_data;
    return this.http.patch(this.apiKeywebsite + 'auth/reset_password', body);
  }

  getToken() {
    return localStorage.getItem('access_token') || '';
  }

  getrefreshToken() {
    return localStorage.getItem('refresh_token') || '';
  }

  saveTokens(tokendata: any) {
    localStorage.setItem('access_token', tokendata.token);
    localStorage.setItem('refresh_token', tokendata.refresh_token);
  }
}

import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  currentYear = new Date().getFullYear();

  ngOnInit() {
    /* Back To Top */

    $(document).ready(function () {
      $(window).on('scroll', function () {
        // if ($(this).scrollTop() > 300) {
        //     $('.back-to-top').fadeIn();
        // } else {
        //     $('.back-to-top').fadeOut();
        // }
        if (event instanceof NavigationEnd) {
          $('.back-to-top').fadeIn();
        } else {
          $('.back-to-top').fadeOut();
        }
      });

      $('.back-to-top').on('click', function () {
        $('html, body').animate({ scrollTop: 0 }, 600);
        return false;
      });
    });
  }
}

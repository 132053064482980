import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from '@angular/router';
import { SidebarService } from './sidebar.service';

import * as $ from 'jquery';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  public menuItems: any = [];
  w_width = window.innerWidth;

  constructor(public sidebarservice: SidebarService, private router: Router) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }

      if (
        event instanceof NavigationEnd &&
        this.w_width < 1025 &&
        (document.readyState == 'complete' || false)
      ) {
        this.toggleSidebar();
        // Hide loading indicator
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });
  }

  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());

    if ($('.wrapper').hasClass('nav-collapsed')) {
      // unpin sidebar when hovered
      $('.wrapper').removeClass('nav-collapsed');
      $('.sidebar-wrapper').unbind('hover');

      $('.logoIcon').addClass('logo-icon45');
      $('.logoIcon').removeClass('logo-icon100');
    } else {
      $('.wrapper').addClass('nav-collapsed');
      $('.sidebar-wrapper').hover(
        function () {
          $('.wrapper').addClass('sidebar-hovered');

          $('.logoIcon').removeClass('logo-icon45');
          $('.logoIcon').addClass('logo-icon100');
        },
        function () {
          $('.wrapper').removeClass('sidebar-hovered');
          $('.logoIcon').addClass('logo-icon45');
          $('.logoIcon').removeClass('logo-icon100');
        }
      );
    }
  }

  // toggleSidebar() {
  //     this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());

  //     if ($(".wrapper").hasClass("nav-collapsed")) {
  //         // unpin sidebar when hovered
  //         $(".wrapper").removeClass("nav-collapsed");
  //         $(".sidebar-wrapper").unbind("hover");

  //         $(".logoIcon").addClass("logo-icon45");
  //         $(".logoIcon").removeClass("logo-icon100");

  //     } else {
  //         $(".wrapper").addClass("nav-collapsed");
  //         $(".sidebar-wrapper").hover(
  //             function () {
  //                 $(".wrapper").addClass("sidebar-hovered");

  //                 $(".logoIcon").removeClass("logo-icon45");
  //                 $(".logoIcon").addClass("logo-icon100");
  //             },
  //             function () {
  //                 $(".wrapper").removeClass("sidebar-hovered");

  //                 $(".logoIcon").addClass("logo-icon45");
  //                 $(".logoIcon").removeClass("logo-icon100");
  //             },
  //         );
  //     }
  // }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
    this.sidebarservice.setSidebarState(true);
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    $.getScript('./assets/js/app-sidebar.js');
  }
}

import { Routes } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'website',
    loadChildren: () =>
      import('../../website/website.module').then((m) => m.WebsiteModule),
  },

  {
    path: 'dashboard',
    loadChildren: () =>
      import('../../../app/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'admin-profile',
    loadChildren: () =>
      import('../../user-profile/user-profile.module').then(
        (m) => m.UserProfileModule
      ),
  },
];

import { RouteInfo } from './sidebar.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard/default',
    title: 'Dashboard',
    icon: 'bx bx-home-circle',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },

  {
    path: '',
    title: 'Website',
    icon: 'fadeIn animated bx bx-world',
    class: 'sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/website/enquiries',
        title: 'Enquiries',
        icon: 'fadeIn animated bx bx-message-square-detail',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/website/subscribers',
        title: 'Subscribers',
        icon: 'fadeIn animated bx bx-envelope',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/website/editFooter',
        title: 'Footer',
        icon: 'fadeIn animated bx bx-message-square-edit',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/website/contacts',
        title: 'Contacts',
        icon: 'fadeIn animated bx bx-user-pin',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/website/aboutus',
        title: 'About Us',
        icon: 'lni lni-network',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Jobs',
    icon: 'lni lni-graduation',
    class: 'sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/website/allJobs',
        title: 'All Jobs',
        icon: 'lni lni-zoom-in',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'CMS',
    icon: 'lni lni-cogs',
    class: 'sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/website/all-strips',
        title: 'All Strips',
        icon: 'fadeIn animated bx bx-layer-plus',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/website/all-sections',
        title: 'All Sections',
        icon: 'fadeIn animated bx bx-layer-plus',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/website/all-services',
        title: 'All Services',
        icon: 'fadeIn animated bx bx-layer-plus',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/website/all-sub-services',
        title: 'All Sub Services',
        icon: 'fadeIn animated bx bx-layer-plus',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/website/edit-hompage',
        title: 'Edit Home',
        icon: 'fadeIn animated bx bx-home',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
];

import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(public router: Router,
    private toastr: ToastrService
  ) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error("Error Event");
          } else {
            // console.log(`error status : ${error.status} ${error.statusText}`);
            switch (error.status) {
              case 401:      //login
                this.toastr.error(
                  `Backend returned code ${error.status},`, error?.error?.message);
                break;
              case 403:     //forbidden
                this.toastr.error(
                  `Backend returned code ${error.status},`, error?.message);
                break;
              case 404:
                this.toastr.error(
                  `Backend returned ${error.status},`, error?.error?.error?.message || error?.error?.message);
            }
          }
        } else {
          console.error("some thing else happened");
        }
        console.log('error', error);
        return throwError(error);
      })
    );
  }
}
